import { Swiper, SwiperSlide } from 'swiper/react'
import { Mousewheel } from 'swiper'
import s from '../../Home.module.css'
import cn from 'classnames'
import { Picture } from '@components/ui'
import { Close, Play } from '@components/icons/home'
import { useEffect, useRef, useState } from 'react'
import ReactModal from 'react-modal'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { NextArrow, PrevArrow } from '../Components/SwiperArrow'

const Modal = ({ isOpen, onClose, url, title }) => {
  const ref = useRef(null)
  const [modalOpened, setModalOpened] = useState(false)

  const progressThresholds = [10, 25, 50, 75, 100]
  const triggeredEvents = []

  const timeupdate = () => {
    const video = ref.current
    const percentage = (video.currentTime / video.duration) * 100
    progressThresholds.forEach((threshold) => {
      if (percentage >= threshold && !triggeredEvents.includes(threshold)) {
        triggeredEvents.push(threshold)
        if (threshold === 100) {
          pageGTMEvent({
            event: 'ga4Event',
            event_name: 'video_complete',
            event_parameters: {
              video_title: title,
              page_group: 'Home Page',
            },
          })
        } else {
          pageGTMEvent({
            event: 'ga4Event',
            event_name: 'video_progress',
            event_parameters: {
              video_title: title,
              page_group: 'Home Page',
              video_percent: threshold,
            },
          })
        }
      }
    })
  }
  useEffect(() => {
    if (modalOpened && ref.current) {
      const video = ref.current
      video.addEventListener('timeupdate', timeupdate)
      return () => video.removeEventListener('timeupdate', timeupdate)
    }
  }, [modalOpened])
  return (
    <>
      <ReactModal
        className={s.modal}
        overlayClassName={s.modalWrap}
        ariaHideApp={false}
        isOpen={isOpen}
        closeTimeoutMS={200}
        onAfterOpen={() => setModalOpened(true)}
        onRequestClose={() => {
          onClose()
          setModalOpened(false) // 重置模态框打开状态
        }}
      >
        <Close
          className={cn(
            'absolute right-7 top-7 z-[1] cursor-pointer text-white min-md:-right-10 min-md:-top-10',
            {
              hidden: !isOpen,
            }
          )}
          onClick={onClose}
        />
        <video
          src={url}
          playsInline
          autoPlay
          controls
          className="min-md:h-[68vh]"
          ref={ref}
        />
      </ReactModal>
    </>
  )
}

/**
 * Renders a slide video component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.section - The section data.
 * @param {string} props.locale - The locale.
 * @param {Object} props.shopCommon - The common shop data.
 * @param {Array} props.relatedProducts - The related products data.
 * @returns {JSX.Element} The rendered component.
 */
const SlideVideo = ({ section, locale, shopCommon, relatedProducts }) => {
  const [start, setStart] = useState(true)
  const [end, setEnd] = useState(false)
  const [swiper, setSwiper] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [url, setUrl] = useState()
  const [title, setTitle] = useState('')

  const handleProgress = (swiper) => {
    setStart(swiper.isBeginning)
    setEnd(swiper.isEnd)
  }

  const settings = {
    modules: [Mousewheel],
    spaceBetween: 8,
    slidesPerView: 'auto' || section.slidesPerView,
    breakpoints: section.breakpoints || {
      1024: {
        allowTouchMove: false,
        slidesOffsetAfter: 220,
      },
      1440: {
        slidesOffsetAfter: 220,
      },
      1920: {
        slidesOffsetAfter: 248,
      },
    },
    onProgress: handleProgress,
    onSwiper: setSwiper,
    mousewheel: {
      releaseOnEdges: true,
      forceToAxis: true,
    },
  }

  const playVideo = (item) => {
    setUrl(item.video)
    setTitle(item.title)
    setIsOpen(true)
    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'video',
      eventAction: 'start',
      eventLabel: item.title,
      nonInteraction: false,
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'lp_button',
      event_parameters: {
        page_group: 'Home Page',
        position: item.title,
        button_name: 'video',
      },
    })
    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'Home Page',
      eventAction: 'watch_video',
      eventLabel: item.title,
      nonInteraction: false,
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'video_start',
      event_parameters: {
        video_title: item.title,
        page_group: 'Home Page',
      },
    })
  }

  return (
    <>
      <div className="layer overflow-hidden">
        <div className="content">
          <div className="py-5 min-l:py-10 min-xl:py-[60px]">
            <h2
              className={s.title}
              dangerouslySetInnerHTML={{ __html: section.title }}
            ></h2>
            {section.lists && (
              <div className="mt-6 min-l:mt-8 min-xl:mt-12">
                <Swiper {...settings} className="!overflow-visible">
                  {section.lists?.map((item, index) => (
                    <SwiperSlide
                      key={index}
                      className={cn(
                        s.picBox,
                        '!w-[298px] min-l:!w-[178px] min-xl:!w-[277px] min-xxl:!w-[390px]'
                      )}
                    >
                      <div
                        className="relative cursor-pointer overflow-hidden rounded-xl"
                        onClick={() => {
                          playVideo(item)
                        }}
                      >
                        <Picture source={item.poster} />
                        {index === 0 && (
                          <video
                            className="absolute left-0 top-0 h-full w-full"
                            src={item.video}
                            muted
                            autoPlay
                            loop
                            playsInline
                          />
                        )}
                        <Play className="absolute bottom-6 right-6 h-8 w-8 min-l:bottom-4 min-l:right-4 min-l:h-[30px] min-l:w-[30px] min-xl:bottom-6 min-xl:right-6 min-xxl:h-10 min-xxl:w-10" />
                        <h4 className="absolute bottom-0 left-0 box-border w-full p-6 pr-20 font-semibold !leading-[1.2] text-white min-l:p-[16px] min-l:pr-[66px] min-l:text-xs min-xl:p-6 min-xl:pr-[66px] min-xl:text-[20px] min-xxl:pr-[80px]">
                          {item.title}
                        </h4>
                      </div>
                    </SwiperSlide>
                  ))}
                  <PrevArrow
                    swiper={swiper}
                    start={start}
                    end={end}
                    title={section.title}
                  />
                  <NextArrow
                    swiper={swiper}
                    start={start}
                    end={end}
                    title={section.title}
                  />
                </Swiper>
                {/* <div
                  className={cn(
                    'hidden items-center justify-end gap-4 min-l:flex',
                    {
                      '!hidden': start && end,
                    }
                  )}
                >
                  <PrevArrow />
                  <NextArrow />
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          url={url}
          title={title}
          onClose={() => {
            setIsOpen(false)
            setUrl('')
          }}
        />
      )}
    </>
  )
}

export default SlideVideo
