const Close = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.386 5.7928C17.9532 5.35996 17.2514 5.35996 16.8186 5.7928L12.1397 10.4717L7.46122 5.79318C7.02838 5.36034 6.3266 5.36034 5.89376 5.79318C5.46092 6.22602 5.46093 6.92779 5.89376 7.36063L10.5722 12.0391L5.89347 16.7179C5.46063 17.1507 5.46063 17.8525 5.89347 18.2853C6.32631 18.7182 7.02809 18.7182 7.46093 18.2853L12.1397 13.6066L16.8189 18.2857C17.2517 18.7186 17.9535 18.7186 18.3863 18.2857C18.8191 17.8529 18.8191 17.1511 18.3863 16.7183L13.7072 12.0391L18.386 7.36025C18.8189 6.92741 18.8189 6.22564 18.386 5.7928ZM18.4606 6.57652C18.4606 6.79618 18.3768 7.01584 18.2092 7.18344L15.7814 9.61126L13.3536 12.0391L13.3536 12.0391L18.2092 7.18347C18.3768 7.01587 18.4606 6.7962 18.4606 6.57652ZM5.83119 6.72068C5.85986 6.89029 5.93964 7.05295 6.07054 7.18385L10.9258 12.0391L10.9258 12.0391L6.07054 7.18381C5.93965 7.05292 5.85986 6.89028 5.83119 6.72068ZM5.82962 17.6376C5.85714 17.81 5.93735 17.9756 6.07025 18.1085C6.40546 18.4437 6.94894 18.4437 7.28415 18.1085L12.1397 13.253L16.9956 18.1089C17.3308 18.4441 17.8743 18.4441 18.2095 18.1089C18.3804 17.938 18.4642 17.713 18.4608 17.4891C18.4642 17.7131 18.3804 17.9381 18.2095 18.1089C17.8743 18.4442 17.3308 18.4442 16.9956 18.1089L12.1397 13.253L7.28415 18.1086C6.94894 18.4438 6.40546 18.4438 6.07025 18.1086C5.93734 17.9757 5.85713 17.81 5.82962 17.6376Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Close
