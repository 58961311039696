const Play = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <circle cx="20" cy="20" r="20" fill="white" fillOpacity="0.3" />
      <path
        fill="white"
        d="m16.2963,25.22952c-0.44011,0.2541 -0.99025,-0.0635 -0.99025,-0.5717l0,-9.31562c0,-0.5082 0.55014,-0.82582 0.99025,-0.57172l8.06751,4.65781c0.4402,0.25409 0.4402,0.88934 0,1.14344l-8.06751,4.65779z"
      />
    </svg>
  )
}

export default Play
